@use 'sass:color';

$color-primary-700: #3d2560;
$color-primary-600: #543385;
$color-primary-500: #3d2560;
$color-primary-400: #d8d3de;
$color-primary-300: #e2dee7;
$color-primary-200: #ece9ef;
$color-primary-100: #f8f4fc;
$color-primary-50: #f1eff3;
$color-primary-overlay: rgba($color-primary-700, 0.67);
$color-gray-50: #f8f4fc;
$color-gray-selected: #f3eff6;
$color-gray-100: #fbfbfb;
$color-gray-200: #f5f3f3;
$color-gray-300: #b2b2b2;
$color-gray-400: #7b7a7a;
$color-gray-500: #554c62;
$color-black: #231f28;
$color-white: #fff;
$color-secondary: #ec6c70;
$color-tertiary: #f19bb9;
$color-quaternary: #00b1b8;
$color-quaternary-light: #00c9d1;
$color-quaternary-hover: rgba($color-quaternary, 0.04);
$color-success: #29c08f;
$color-success-dark: #1b7e5f;
$color-warning: #fbd300;
$color-danger: #fb3b40;
$color-info: #5985f8;
$color-background: $color-primary-200;

// Old
$indigo: #3d2560;
$pacific-blue: #00b1b8;
$sunglo: #ec6c70;
$sunglo-light: color.adjust($sunglo, $lightness: 10%);
$red: #ff0000c4;
$primary: $color-primary-700;
$secondary: $color-secondary;
$error: $color-secondary;
$error-light: $color-tertiary;
$delete: $color-danger;
$quaternary: $color-quaternary;
$info: $color-info;

// the :export directive is the magic sauce for webpack
:export {
  primary: $primary;
  primaryLight: $color-primary-600;
  primary200: $color-primary-200;
  primary300: $color-primary-300;
  primary400: $color-primary-400;
  primary700: $color-primary-700;
  primaryOverlay: $color-primary-overlay;
  secondary: $secondary;
  secondaryLight: $color-tertiary;
  quaternary: $color-quaternary;
  quaternaryLight: $color-quaternary-light;
  quaternaryHover: $color-quaternary-hover;
  error: $color-danger;
  warning: $color-warning;
  info: $info;
  success: $color-success;
  successDark: $color-success-dark;
  background: $color-background;
  errorLight: $error-light;
  delete: $delete;
  white: $color-white;
  graySelected: $color-gray-selected;
  gray100: $color-gray-100;
  gray200: $color-gray-200;
  gray300: $color-gray-300;
  gray400: $color-gray-400;
  gray500: $color-gray-500;
  black: $color-black;
}
