:root {
  --loader-offset-top: calc(50% - 12px);
  --loader-offset-right: calc(50% - 25px);
  --loader-size: 50px;
}

.loader {
  position: absolute;
  top: var(--loader-offset-top);
  right: var(--loader-offset-right);
  border: 8px solid #f3f3f3;
  border-top: 8px solid #0cc;
  border-radius: 50%;
  width: var(--loader-size);
  height: var(--loader-size);
  animation: spin 0.5s linear infinite;
  z-index: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  background: linear-gradient(0deg, $secondary 15%, $primary 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99999999999999999;
}

.text {
  position: absolute;
  top: calc(var(--loader-offset-top) + 2 * (var(--loader-size)));
  color: #fff;
  text-shadow: #474747 2px 3px 2px;
}
